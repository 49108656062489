import { useState, useEffect } from "react";
import { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import routes from "./config";
import { Styles } from "../styles/styles";
import LoadingSpinner from "../components/LoadingSpinner"; 

const Router = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isFadeIn, setIsFadeIn] = useState(false);

  useEffect(() => {
    // Simulate a delay to see the LoadingSpinner
    const delay = setTimeout(() => {
      setIsLoading(false);
      setIsFadeIn(true);
    }, 2000); // Adjust the delay time as needed (in milliseconds)

    return () => clearTimeout(delay);
  }, []);

  return (
    <Suspense fallback={<LoadingSpinner />}>
        <div className={`fade-in-${isFadeIn}`}>
          <Styles />
          <Header />
          <Switch>
            {routes.map((routeItem) => {
              const Component = lazy(() => import(`../pages/${routeItem.component}`));
              return (
                <Route
                  key={routeItem.component}
                  path={routeItem.path}
                  exact={routeItem.exact}
                  render={(props) => (
                    <Suspense fallback={<LoadingSpinner />}>
                      <Component {...props} />
                    </Suspense>
                  )}
                />
              );
            })}
          </Switch>
          <Footer />
        </div>
      
    </Suspense>
  );
};

export default Router;
