import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import {
  FooterSection,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Language,
  ContactInfo,
  ContactIcon,
} from "./styles";

const Footer = ({ t }: any) => {
  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="center" gutter={[30, 30]}>
            <Col lg={6} md={6} sm={12} xs={12}>
              <NavLink to="/">
                <LogoContainer>
                  <SvgIcon src="../logo_transparent.png" width="150px" height="54px" />
                </LogoContainer>
              </NavLink>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Language>{t("Address")}</Language>
              <Para>10 Enigma Cl</Para>
              <Para>Speewah</Para>
              <Para>Queensland</Para>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Language>{t("Contact")}</Language>
              <ContactInfo>
                <ContactIcon>
                  <MailOutlined />
                </ContactIcon>
                <Para>anneke@boology.com.au</Para>
              </ContactInfo>
              <ContactInfo>
                <ContactIcon>
                  <PhoneOutlined />
                </ContactIcon>
                <Para>+61 487 064 325</Para>
              </ContactInfo>
            </Col>
          </Row>
          <Extra>
            <Para>
              © 2023 Boonooloo Psychology. All rights reserved.
            </Para>
          </Extra>
        </Container>
      </FooterSection>
    </>
  );
};

export default withTranslation()(Footer);
