const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
    subdomain: "main",
  },
  {
    path: "/psychology",
    exact: true,
    component: "Psychology",
    subdomain: "psychology",
  },
  {
    path: "/equine",
    exact: true,
    component: "Equine",
    subdomain: "equine",
  },
  {
    path: "/referral",
    exact: true,
    component: "Referral",
    subdomain: "referral",
  },
  {
    path: "/contact",
    exact: true,
    component: "Contact",
    subdomain: "contact",
  },
  {
    path: "/faq",
    exact: true,
    component: "FAQ",
    subdomain: "faq",
  },
];

export default routes;
