import styled from "styled-components";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";

export const HeaderSection = styled("header")`
  padding: 1rem 0.5rem;
  position: relative;
  min-height: 85vh;
`;

export const BackgroundImage = styled.div<{ isDefaultPage: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../img/background_compressed.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top calc(0px - 12vw); /* Adjust the 10px as needed */
`;

export const LogoContainer = styled(Link)`
  display: block;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (min-width: 411px) {
    right: 50px;
  }
`;

export const NavLink = styled(Link)`
  display: inline-block;
  text-align: center;
`;

export const CustomNavLink = styled("div")`
  width: 203px;
  display: inline-block;

  @media only screen and (max-width: 411px) {
    width: 150px;
  }

  @media only screen and (max-width: 320px) {
    width: 118px;
  }
`;

export const ContactWrapper = styled("div")<any>`
  cursor: pointer;
  width: ${(p) => (p.width ? "100%" : "110px")};
  font-weight: 700;
  text-align: center;
  border-radius: 1.25rem;
  display: inline-block;
`;

export const Burger = styled("div")`
  @media only screen and (max-width: 890px) {
    display: block;
    padding: 1rem;
  }

  display: none;

  svg {
    fill: #2e186a;
  }
`;

export const NotHidden = styled("div")`
  @media only screen and (max-width: 890px) {
    display: none;
  }
`;

export const Menu = styled("h5")`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
`;

export const NavContainer = styled.div`
  @media (max-width: 1080px) and (min-width: 890px)  {
    /* If screen width is over 1080px */
    margin-left: 120px; /* Reset left padding */
    white-space: nowrap;
  }
  @media only screen and (min-width: 890px) {
    display: flex;
    align-items: center;
  }
`;

export const CustomNavLinkSmall = styled(NavLink)`
  cursor: pointer;
  font-size: 1.2rem;
  color: #18216d;
  margin: 0.5rem 2rem;

  &:hover,
  &:active,
  &:focus {
    color: rgb(5, 160, 158);
    position: relative;
  }

  @media only screen and (max-width: 890px) {
    margin: 1.25rem 2rem;
    text-align: left;
  }
`;

export const CustomNavLinkExternal = styled("a")`
  cursor: pointer;
  font-size: 1.2rem;
  color: #18216d;
  margin: 0.5rem 2rem;

  &:hover,
  &:active,
  &:focus {
    color: rgb(5, 160, 158);
    position: relative;
  }

  @media only screen and (max-width: 890px) {
    margin: 1.25rem 2rem;
  }
`;

export const Dropdown = styled.div`
  position: relative;
  display: inline-block;
  @media only screen and (min-width: 890px) {
    text-align: center;
  }
`;

export const DropdownItem = styled.div`
  display: none;
  min-width: 160px;
  z-index: 1;
  background-color: #ffffff;

  @media only screen and (min-width: 890px) {
    position: absolute;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
  }

  ${Dropdown}:hover & {
    @media only screen and (min-width: 890px) {
      display: block;
    }
  }

  @media only screen and (max-width: 890px) {
    text-align: left;
    display: block;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const Label = styled("span")`
  font-weight: 500;
  color: #404041;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const TopMenu = styled.div`
  position: relative;
  top: 0;
  z-index: 100;
  padding-top: 0.5rem;
`;

export const Outline = styled(MenuOutlined)<any>`
  font-size: 22px;
`;

export const Span = styled.span`
  transition: all 0.3s ease-in-out;
  right: 50%;
  transform: translate(50%, 0%); /* Center both horizontally and vertically */
  &:hover::before {
    content: "";
    position: absolute;
    top: 25px; /* Center vertically */
    width: 0;
    height: 12px; /* Adjust the height as needed */
    background-image: url("../img/svg/squiggle.svg");
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: fill;
    opacity: 0;
    animation: fly-in 0.3s forwards;
  }

  @keyframes fly-in {
    0% {
      width: 0;
      opacity: 0;
    }
    100% {
      width: 100px; /* Adjust the width as needed */
      opacity: 1;
    }
  }
`;

export const CenteredSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  padding: 0 15vw 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

  @media only screen and (max-width: 890px) {
    padding: 1rem;
  }
`;


export const HeaderTitle = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
`;

export const HeaderText = styled.p`
  font-size: 1.2rem;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);

  @media only screen and (max-width: 890px) {
    font-size: 1rem;
  }
`;

export const FloatingArrow = styled.div`
  width: 50px;
  height: 50px;
  position: relative;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 16px;
    height: 16px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transition: border-color 0.3s;
  }

  &:hover::before {
    border-color: #ccc;
  }

  animation: floating 1.5s infinite ease-in-out;

  @keyframes floating {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(0);
    }
  }
`;