import styled from "styled-components";
import { Link } from "react-router-dom";

export const FooterSection = styled.footer`
  bottom: 0px;
  background: rgb(241, 242, 243);
  padding-top: 4rem;
  padding-bottom: 0;
`;

export const Squiggle = styled.div`
  width: 100%;
  height: 30px;
  background-image: url("/img/svg/squiggle.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  background-size: 20% auto;
`;

export const NavLink = styled(Link)`
  display: inline-block;
  color: #18216d;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: rgb(5, 160, 158);
  }
`;

export const Extra = styled.section`
  background: rgb(241, 242, 243);
  padding: 1rem;
  display: flex;
  justify-content: center;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 150px;
    height: 54px;
  }
`;

export const Para = styled.div`
  color: #18216d;
  font-size: 14px;
  line-height: 1.8;
`;

export const FooterContainer = styled.div`
  color: #18216d;
  font-size: 14px;
  text-align: right;
`;

export const Language = styled.h4`
  font-size: 22px;
  text-transform: capitalize;
  color: #18216d;
  margin-bottom: 1rem;
`;

export const ContactInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

export const ContactIcon = styled.div`
  margin-right: 8px;

  svg {
    color: #18216d;
    font-size: 18px;
  }
`;
