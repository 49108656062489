import React from "react";
import styled, { keyframes } from "styled-components";
import { Row } from "antd";

// Container for the SVG
const SpinnerContainer = styled.div`
  background: white;
  width: 100vw; 
  height: 100vh;
`;

// Styled SVG element
const SquiggleSvg = styled.svg`
  right: 50%;
  transform: translate(50%, 0%); /* Center both horizontally and vertically */
  position: absolute;
  top: 25px; /* Center vertically */
  width: 0;
  opacity: 0;
  height: 50vh; /* Adjust the height as needed */
  background-image: url("../img/svg/squiggle.svg");
  background-repeat: no-repeat;
  background-position: left;
  background-size: fill;
  animation: fly-in 2s infinite;
  }

  @keyframes fly-in {
    0% {
      width: 0;
      opacity: 0;
    }
    100% {
      width: 100px; /* Adjust the width as needed */
      opacity: 1;
    }
  }

  `;



const LoadingSpinner = () => {
  return (
    <Row justify="center" align="middle">
      <SpinnerContainer>
        {/* Placeholder SVG to ensure proper sizing and animation */}
        <SquiggleSvg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
          <path d="M 10 300 Q 50 320 100 270 C 150 220 200 320 250 290 Q 300 260 350 290 C 400 320 450 300 490 300" fill="none" strokeWidth="15" />
        </SquiggleSvg>
      </SpinnerContainer>
    </Row>
  );
};

export default LoadingSpinner;
