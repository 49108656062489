import { useState, useEffect } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import { useLocation } from "react-router-dom";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  BackgroundImage,
  NotHidden,
  Menu,
  NavContainer,
  CustomNavLinkSmall,
  CustomNavLinkExternal,
  Label,
  Outline,
  Span,
  Dropdown,
  DropdownItem,
  TopMenu,
  CenteredSection,
  HeaderTitle,
  HeaderText,
  FloatingArrow,
} from "./styles";

const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);
  const [currentPage, setCurrentPage] = useState("");
  const location = useLocation();

  useEffect(() => {
    // Extract the current subpath from the URL
    const subpath = location.pathname.split("/")[1];
    
    if (subpath) {
      setCurrentPage(subpath);
    } else {
      setCurrentPage("default");
    }
  }, [location.pathname]);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(false);
  };

  const MenuItem = () => {
    return (
      <NavContainer>
        <CustomNavLinkSmall onClick={onClose} to="/psychology">
          <Span>{t("Psychology")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={onClose} to="/equine">
          <Span>{t("Equine Therapy")}</Span>
        </CustomNavLinkSmall>
        <Dropdown>
          <CustomNavLinkSmall onClick={onClose} to="/referral">
            <Span>{t("Referral")}</Span>
          </CustomNavLinkSmall>
          <DropdownItem>
            <CustomNavLinkSmall onClick={onClose} to="/faq">{t("FAQ")}</CustomNavLinkSmall>
            <CustomNavLinkSmall onClick={onClose} to="/contact">{t("Contact")}</CustomNavLinkSmall>
          </DropdownItem>
        </Dropdown>
        <CustomNavLinkExternal style={{ width: "160px" }}  href="https://clientportal.powerdiary.com/clientportal/boology" target="_blank">
          <Button>{t("Book a session")}</Button>
        </CustomNavLinkExternal>
      </NavContainer>
    );
  };

  const determineHeaderContent = () => {
    if (currentPage === "default") {
      return (
        <>
            <HeaderTitle>Welcome</HeaderTitle>
            <HeaderText>
              Boonooloo Psychology is a locally owned and managed mental health clinic in Speewah. We are passionate about delivering mental health services to all patients in Cairns and the Tablelands.
            </HeaderText>
        </>
      );
    } 
    else if (currentPage === "equine") {
      return (
        <>
          <HeaderTitle>Equine Therapy</HeaderTitle>
          <HeaderText></HeaderText>
        </>
      );
    }
    else {
      const capitalizedSubdomain = currentPage.charAt(0).toUpperCase() + currentPage.slice(1);
      return (
        <>
          <HeaderTitle>{capitalizedSubdomain}</HeaderTitle>
          <HeaderText></HeaderText>
        </>
      );
    }
  };

  return (
    <HeaderSection>
      <BackgroundImage isDefaultPage={currentPage === "default"}>
        <Container>
          <TopMenu>
            <Row justify="space-between">
              <LogoContainer to="/" aria-label="homepage">
                <SvgIcon src="../logo_transparent.png" width="170px" height="61px" />
              </LogoContainer>
              <NotHidden>
                <MenuItem />
              </NotHidden>
              <Burger onClick={showDrawer}>
                <Outline />
              </Burger>
            </Row>
            <Drawer closable={false} visible={visible} onClose={onClose}>
              <Col style={{ marginBottom: "2.5rem" }}>
                <Label onClick={onClose}>
                  <Col span={12}>
                    <Menu>Menu</Menu>
                  </Col>
                  <Col span={12}>
                    <Outline />
                  </Col>
                </Label>
              </Col>
              <MenuItem />
            </Drawer>
          </TopMenu>
          <CenteredSection>
            {determineHeaderContent()}
            <FloatingArrow />
          </CenteredSection>
        </Container>
      </BackgroundImage>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
